const headerPhone = document.querySelector('.header-phone')
headerPhone.addEventListener('click', headerPhoneClick)
function headerPhoneClick(e) {
  e.target.innerHTML =
    '<a style="width:100%; height:auto" href="tel:' +
    e.target.dataset.link +
    '">' +
    e.target.dataset.phone
  headerPhone.removeEventListener('click', headerPhoneClick)
}

const footerPhone = document.querySelector('.footer-phone')
footerPhone.addEventListener('click', footerPhoneClick)
function footerPhoneClick(e) {
  e.target.innerHTML =
    '<a style="width:100%; height:auto" href="tel:' +
    e.target.dataset.link +
    '">' +
    e.target.dataset.phone
  footerPhone.removeEventListener('click', footerPhoneClick)
}

const footerEmail = document.querySelector('.footer-email')
footerEmail.addEventListener('click', footerEmailClick)
function footerEmailClick(e) {
  e.target.innerHTML =
    '<a style="width:100%; height:auto" href="tel:' +
    e.target.dataset.email +
    '">' +
    e.target.dataset.email
  footerEmail.removeEventListener('click', footerEmailClick)
}

const cbxs = document.querySelectorAll('.cbx')
cbxs.forEach((e) => {
  const checkbox = e.previousSibling
  e.addEventListener('click', (el) => {
    !checkbox.checked
      ? e.parentElement.classList.remove('order-cbx-dis')
      : e.parentElement.classList.add('order-cbx-dis')
  })
})
const cCbxs = document.querySelectorAll('.c-cbx')
cCbxs.forEach((e) => {
  const checkbox = e.previousSibling
  e.addEventListener('click', (el) => {
    !checkbox.checked
      ? e.parentElement.classList.remove('calculator-options-cbx-dis')
      : e.parentElement.classList.add('calculator-options-cbx-dis')
  })
})
const questions = document.querySelectorAll('.questions-item')
questions.forEach((e) => {
  const answer = e.nextElementSibling
  const img = e.querySelector('img')
  let toggle = true
  e.addEventListener('click', (el) => {
    toggle = !toggle
    toggle
      ? (img.src ='http://wp-351.vtop-3.ru/wordpress/wp-content/themes/artrutrade/assets/img/icons/plus.svg')
      : (img.src = 'http://wp-351.vtop-3.ru/wordpress/wp-content/themes/artrutrade/assets/img/icons/minus.svg')
    answer.classList.toggle('hidden')
  })
})
const input = document.querySelectorAll('.order-input')
const input2 = document.querySelectorAll('.date')
Inputmask('+375 (99)999-99-99').mask(input)
Inputmask('99.99.9999').mask(input2)

let screen = ''
const calcNexts = document.querySelectorAll('.calc-next')
calcNexts.forEach((e) => {
  const next = e.dataset.next
  const img = e.querySelector('.calculator-name img')
  img.src = 'http://wp-351.vtop-3.ru/wordpress/wp-content/themes/artrutrade/assets/img/calculator/bullet.svg'
  e.addEventListener('click', (el) => {
    removeBullets(calcNexts)
    img.src = 'http://wp-351.vtop-3.ru/wordpress/wp-content/themes/artrutrade/assets/img/calculator/bullet-fill.svg'
    screen = next
  })
})

function removeBullets(calcNexts) {
  calcNexts.forEach((e) => {
    const img = e.querySelector('.calculator-name img')
    img.src = 'http://wp-351.vtop-3.ru/wordpress/wp-content/themes/artrutrade/assets/img/calculator/bullet.svg'
  })
}

const calcBtns = document.querySelectorAll('.calculator-btn')
calcBtns.forEach((e) => {
  const block = e.parentElement
  e.addEventListener('click', (el) => {
    if (!screen) return
    console.log(screen)
    block.classList.add('hidden')
    const newBlock = document.querySelector(screen)
    newBlock.classList.remove('hidden')
    screen === '#screen3' ? (screen = '#screen4') : false
    screen === '#screen5' ? (screen = '#screen4') : false
    screen === '#screen6' ? (screen = '#screen4') : false
    screen === '#screen7' ? (screen = '#screen4') : false
  })
})
